import { Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";

import words from "../words.json";

export const About: React.FC = () => (
  <Flex
    maxW='100%'
    justifyContent='center'
    alignItems='center'
    bgColor={useColorModeValue("#f0f0f0", "dark")}
    flexDirection='column'
  >
    <Heading
      bgGradient={useColorModeValue(
        "linear(to-r, #7928CA, #2d0425)",
        "linear(to-r, #f0f0f0, #7928CA)"
      )}
      bgClip='text'
      fontWeight={100}
      py={4}
    >
      {words.greeting}
    </Heading>
    <Text
      bgGradient={useColorModeValue(
        "linear(to-r, #7928CA, #2d0425)",
        "linear(to-r, #f0f0f0, #7928CA)"
      )}
      bgClip='text'
      maxW='70vh'
      px={4}
      fontWeight={"extrabold"}
      fontSize={{ base: "md", md: "lg" }}
    >
      {`${words.about} ${words.passion}`}
    </Text>
  </Flex>
);
