import { Flex, Link, Stack, Text } from "@chakra-ui/react";

import { NAV_ITEMS, NavItem } from "../navigation.setting";

export const MobileNav: React.FC = () => (
  <Stack p={4} display={{ md: "none" }}>
    {NAV_ITEMS.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
  </Stack>
);

const MobileNavItem = ({ label, href }: NavItem) => (
  <Stack spacing={4}>
    <Flex
      py={2}
      as={Link}
      href={href ?? "#"}
      justify={"space-between"}
      align={"center"}
      _hover={{ textDecoration: "none" }}
    >
      <Text fontWeight={600}>{label}</Text>
    </Flex>
  </Stack>
);
