import {
  Box,
  Flex,
  Heading,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import hero from "../assets/hero.jpeg";
import words from "../words.json";

export const Hero: React.FC = () => (
  <Box bgImage={hero} bgSize='cover' h='40vh' bgPosition='center center'>
    <Flex bgColor='rgba(0, 0, 0, 0.7)' w='full' h='full'>
      <VStack
        w='full'
        justify='center'
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent"}
      >
        <Heading
          color='white'
          fontWeight={400}
          lineHeight={1.2}
          fontSize={useBreakpointValue({ base: "3xl", md: "6xl" })}
          opacity={1}
          w='50%'
        >
          {words.hero_pitch}
        </Heading>
      </VStack>
    </Flex>
  </Box>
);
