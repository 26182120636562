import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import cloudImg from "../assets/cloudImg.jpeg";
import dTImage from "../assets/dTImage.jpeg";
import pmTypes from "../assets/pmTypes.jpeg";
import words from "../words.json";

interface FeatureProps {
  title: string;
  text: string;
  img: string;
}

const contents: FeatureProps[] = [
  {
    title: words.title1,
    text: words.text1,
    img: dTImage,
  },
  {
    title: words.title2,
    text: words.text2,
    img: cloudImg,
  },
  {
    title: words.title3,
    text: words.text3,
    img: pmTypes,
  },
];

const SplitFeature = ({ title, text, img }: FeatureProps, index: number) => (
  <Grid key={index}>
    <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} alignItems='center'>
      <Flex>
        <Image alt={"feature image" + index} src={img} objectFit='cover' />
      </Flex>
      <Stack spacing={4}>
        <Heading fontSize={{ base: "xl", md: "2xl" }} fontWeight={100} pt={4}>
          {title}
        </Heading>
        <Text
          fontWeight={400}
          fontSize={{ base: "sm", md: "lg" }}
          bgGradient={useColorModeValue(
            "linear(to-r, #0e1111, #2d0425)",
            "linear(to-r, #f0f0f0, #7928CA)"
          )}
          bgClip='text'
          px={16}
          pb={4}
        >
          {text}
        </Text>
      </Stack>
    </SimpleGrid>
  </Grid>
);

export const Features: React.FC = () => {
  return (
    <Box py={8} w='full' bgColor={useColorModeValue("#f0f0f0", "dark")}>
      <Heading
        bgGradient={useColorModeValue(
          "linear(to-r, #7928CA, #2d0425)",
          "linear(to-r, #f0f0f0, #7928CA)"
        )}
        bgClip='text'
        fontWeight={100}
        py={4}
      >
        What I do ...
      </Heading>
      <SimpleGrid columns={1}>
        {contents.map((item, index) => (
          <SplitFeature {...item} key={index} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
