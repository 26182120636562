import { Box, Link, Stack, useColorModeValue } from "@chakra-ui/react";

import { NAV_ITEMS } from "../navigation.setting";

export const DesktopNav: React.FC = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  return (
    <Stack direction='row' spacing={8} mr={16}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            p={2}
            href={navItem.href ?? "#"}
            fontSize='sm'
            fontWeight={500}
            color={linkColor}
          >
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};
