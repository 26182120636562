import { extendTheme } from "@chakra-ui/react";
import "@fontsource/montserrat/200.css";
import "@fontsource/noto-sans-jp/400.css";

export const theme = extendTheme({
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Noto Sans JP, sans-serif",
  },
});
