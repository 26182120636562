import { ChakraProvider, Flex } from "@chakra-ui/react";

import { About } from "./components/About";
import { Features } from "./components/Features";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { NavBar } from "./components/NavBar";
import { theme } from "./theme/theme";

export const App: React.FC = () => (
  <ChakraProvider theme={theme}>
    <Flex textAlign='center' fontSize='xl' flexDir='column'>
      <NavBar />
      <Hero />
      <About />
      <Features />
      <Footer />
    </Flex>
  </ChakraProvider>
);
