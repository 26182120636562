import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaLinkedin } from "react-icons/fa";

import { NAV_ITEMS } from "../navigation.setting";
import { NavLogo } from "./NavLogo";

const currentYear = new Date().getFullYear();

export const Footer = () => (
  <Box>
    <Container
      as={Stack}
      maxW='full'
      py={4}
      justify='center'
      align='center'
      bgColor={useColorModeValue("#f0f0f0", "dark")}
    >
      <NavLogo />
      <Stack direction='row' spacing={8}>
        {NAV_ITEMS.map((navItem) => (
          <Link
            key={navItem.label}
            p={2}
            href={navItem.href ?? "#"}
            fontSize='xs'
          >
            {navItem.label}
          </Link>
        ))}
      </Stack>
    </Container>
    <Box
      borderTopWidth={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Container
        as={Stack}
        maxW='6xl'
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text fontSize='xs'>
          &copy; {currentYear} Hiroyuki Momoi. All rights reserved
        </Text>
        <Stack direction='row' spacing={6}>
          <Text fontSize='xs' fontWeight='100' alignSelf='center'>
            Tokyo, JP
          </Text>
          <SocialButton
            label='LinkedIn'
            href='https://jp.linkedin.com/in/hiroyuki-momoi'
          >
            <FaLinkedin />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  </Box>
);

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded='full'
      w={8}
      h={8}
      cursor='pointer'
      as={"a"}
      href={href}
      display='inline-flex'
      alignItems='center'
      justifyContent='center'
      transition='background 0.3s ease'
      _hover={{ bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200") }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
